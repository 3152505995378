.chat-input-form {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 900px;
  padding: 0px 20px;
  margin-bottom: 40px;
}

.chat-search-input {
  position: relative;
}

#chat-input-button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 999;
}

.chat-search-send-icon {
  width: 24px;
}

.chat-loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.result::-webkit-scrollbar {
  display: none;
}

.result-title {
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
