.result-data {
  display: flex;
  align-items: start;
  gap: 20px;
}

.result-content {
  border: 0.5px solid var(--gray-300, #d0d5dd);
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 30px;
}

.result-text-only {
  white-space: pre-line; /* Preserve newline characters and wrap text as necessary */
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 0.5px solid var(--gray-300, #d0d5dd);
  border-radius: 12px;
  margin-bottom: 30px;
  margin-left: 20px;
  width: 750px;
}

.chat-icon-image {
  width: 48px;
}

.confirmation-chat-icon-image {
  margin-right: 20px;
}

.chat-result-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message-section,
.chart-section,
.confirmation-feedback-section {
  display: flex;
  align-items: flex-start;
}

.result-confirmation-message {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.confirmation-yes-section,
.confirmation-no-section {
  margin-bottom: 30px;
}

.confirmation-yes-section > div,
.confirmation-no-section > div {
  display: flex;
  align-items: center;
}

.confirmation-button-clicked {
  margin-left: 20px;
}

#confirm-response-yes-clicked {
  font-size: 16px;
  font-weight: 600;
  background-color: #e6f3ff;
  color: #0073bb;
  &:hover {
    background-color: #e6f3ff;
    cursor: not-allowed;
  }
}

#confirm-response-no-clicked {
  font-size: 16px;
  font-weight: 600;
  background-color: #fde7e9;
  color: #d13212;
  &:hover {
    background-color: #fde7e9;
    cursor: not-allowed;
  }
}

.confirmation-feedback-content {
  width: 750px;
}
