.result-confirmation-message {
  .confirmation-container {
    display: flex;
    gap: 16px;
    justify-content: flex-start;

    .confirmation-button {
      min-width: 120px;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: 600;
    }

    .confirmation-loader {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 10px;
    }
  }
}
